import { useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { colors } from './colors';

type CosmosLogoProps = {
  width?: number;
  height?: number;
};

export const CosmosLogo: React.FC<CosmosLogoProps> = ({
  width = 100,
  height = 100,
}) => {

  const color = useColorModeValue(colors.colorDark, colors.colorLight);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 1024 1024" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M559.489 834.999C575.149 766.673 586.037 671.007 588.667 562.846L612.66 563.429C610.005 672.632 599.01 769.997 582.883 840.361C574.842 875.446 565.383 904.46 554.695 924.986C549.359 935.233 543.415 943.961 536.702 950.27C529.942 956.624 521.636 961.2 512 961.2C500.94 961.2 491.681 955.206 484.372 947.318C477.039 939.402 470.522 928.41 464.667 915.38C452.92 889.241 442.683 852.265 434.371 807.75L457.963 803.345C466.127 847.065 475.94 881.915 486.558 905.543C491.885 917.397 497.13 925.774 501.978 931.006C506.851 936.266 510.194 937.2 512 937.2C513.589 937.2 516.306 936.503 520.265 932.783C524.27 929.017 528.739 922.868 533.408 913.902C542.727 896.005 551.637 869.259 559.489 834.999Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M472.024 159.599C457.662 209.824 446.256 281.346 440 365.329L416.066 363.546C422.388 278.678 433.97 205.382 448.949 153.001C456.413 126.9 464.908 105.31 474.426 89.9811C483.479 75.4018 495.808 62.8003 511.998 62.8003C521.634 62.8003 529.94 67.3757 536.7 73.7298C543.412 80.0394 549.357 88.7673 554.692 99.0135C565.381 119.54 574.839 148.554 582.881 183.639C599.008 254.003 610.003 351.368 612.657 460.571L588.665 461.154C586.035 352.993 575.147 257.327 559.487 189.001C551.635 154.741 542.725 127.995 533.405 110.098C528.737 101.132 524.268 94.9826 520.262 91.2174C516.304 87.4967 513.586 86.8003 511.998 86.8003C509.194 86.8003 503.102 89.2971 494.815 102.642C486.994 115.237 479.231 134.397 472.024 159.599Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M440.002 365.329C436.591 411.12 434.719 460.503 434.719 512C434.719 624.426 443.64 726.638 457.963 803.345L434.37 807.75C419.716 729.271 410.719 625.538 410.719 512C410.719 459.931 412.611 409.952 416.068 363.546L440.002 365.329Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M428.801 552.768C350.948 505.24 283.687 457.047 233.733 414.458L218.162 432.722C269.286 476.307 337.624 525.225 416.296 573.252L428.801 552.768Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M767.494 714.63C700.498 694.032 612.212 655.633 517.234 603.835L505.743 624.905C601.636 677.202 691.446 716.357 760.441 737.57C794.843 748.147 824.697 754.462 847.816 755.468C859.356 755.97 869.886 755.186 878.706 752.527C887.588 749.849 895.703 744.944 900.521 736.6C908.648 722.523 903.832 705.465 895.658 690.267C887.059 674.28 872.482 656.027 853.448 636.401C815.251 597.014 757.103 550.07 686.25 501.935L672.763 521.787C742.879 569.422 799.58 615.329 836.219 653.109C854.604 672.066 867.443 688.477 874.521 701.636C882.024 715.585 881.146 722.158 879.736 724.6C878.942 725.975 876.98 727.98 871.779 729.548C866.516 731.135 858.957 731.93 848.859 731.491C828.702 730.613 801.087 724.958 767.494 714.63Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M550.142 445.073C594.052 470.424 635.28 496.323 672.762 521.787L686.249 501.935C648.265 476.13 606.54 449.922 562.142 424.288C457.09 363.637 356.566 317.459 278.943 291.673C240.225 278.812 206.608 270.82 180.71 268.863C167.793 267.887 156.102 268.352 146.399 270.883C136.678 273.419 127.677 278.403 122.483 287.4C116.91 297.053 117.522 308.162 120.767 318.51C124.025 328.902 130.394 340.143 138.889 351.844C155.934 375.32 183.256 402.964 218.161 432.722L233.731 414.459C199.44 385.224 173.744 359 158.31 337.744C150.567 327.079 145.833 318.237 143.667 311.33C141.488 304.381 142.358 300.976 143.267 299.4C144.118 297.927 146.344 295.701 152.457 294.106C158.588 292.507 167.34 291.921 178.901 292.795C201.959 294.537 233.439 301.847 271.377 314.45C347.064 339.592 446.084 384.995 550.142 445.073Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M520.48 418.94C601.097 375.105 676.844 340.946 738.738 319.198L730.782 296.555C667.411 318.822 590.478 353.561 509.016 397.855L520.48 418.94Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M208.476 632.887C259.443 585.405 336.327 528.414 428.139 472.245L415.614 451.773C322.917 508.484 244.615 566.418 192.116 615.326C165.939 639.713 145.692 662.301 133.359 681.732C127.202 691.432 122.654 700.906 120.571 709.841C118.473 718.842 118.68 728.286 123.48 736.6C131.596 750.657 148.739 755.021 165.952 755.552C184.057 756.111 207.098 752.635 233.547 745.995C286.627 732.669 356.187 705.881 433.135 668.71L422.696 647.099C346.549 683.883 278.611 709.937 227.704 722.717C202.16 729.13 181.585 732.023 166.692 731.564C150.909 731.077 145.672 727.037 144.265 724.6C143.473 723.229 142.72 720.542 143.945 715.29C145.184 709.973 148.241 703.07 153.622 694.593C164.363 677.669 182.919 656.695 208.476 632.887Z" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M551.138 578.927C506.978 604.423 463.696 627.293 422.695 647.099L433.135 668.709C474.685 648.638 518.488 625.491 563.138 599.711C668.19 539.06 758.443 475.093 819.586 420.762C850.083 393.662 873.812 368.545 888.456 347.094C895.76 336.397 901.203 326.039 903.862 316.37C906.527 306.684 906.711 296.397 901.516 287.4C896.007 277.858 886.239 272.837 875.802 270.442C865.332 268.039 852.623 267.871 838.494 269.285C810.15 272.121 773.234 281.637 730.78 296.555L738.736 319.197C780.427 304.548 815.286 295.727 840.884 293.166C853.726 291.881 863.531 292.25 870.433 293.834C877.369 295.426 879.832 297.842 880.732 299.4C881.582 300.873 882.398 303.914 880.722 310.005C879.041 316.115 875.173 323.986 868.635 333.562C855.597 352.659 833.527 376.267 803.644 402.821C744.026 455.797 655.196 518.849 551.138 578.927Z" fill={color}/>
      <circle cx="449.975" cy="804.495" r="31.3505" fill={color}/>
      <circle r="31.3505" transform="matrix(0.5 -0.866025 -0.866025 -0.5 227.181 419.466)" fill={color}/>
      <circle r="31.3505" transform="matrix(0.5 0.866025 0.866025 -0.5 734.794 312.039)" fill={color}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M512.012 457.433C542.156 457.433 566.592 481.869 566.592 512.012C566.592 542.156 542.156 566.592 512.012 566.592C481.869 566.592 457.433 542.156 457.433 512.012C457.433 481.869 481.869 457.433 512.012 457.433Z" fill={color}/>
    </svg>
  );
};
